<script setup lang="ts">
  import { computed, ref, Ref, watch, defineEmits, defineProps } from 'vue'
  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { DatePicker } from 'v-calendar'
  import 'v-calendar/style.css'
  import { RRuleSet } from 'rrule'
  import { format, differenceInMilliseconds, addMilliseconds, addHours, isSameDay } from "date-fns";
  import moment from 'moment'
  import axios from 'axios'

  import { useEventStore } from '@/store/event'
  import { EventOccurrence } from '@/types'
  import RecurrenceForm from '@/components/Event/occurrence/partials/RecurrenceForm.vue'

  const props = defineProps(['date'])
  const emit = defineEmits(['created', 'cancelled'])

  const store = useEventStore()

  const allDay: Ref<boolean> = ref(false)
  const startDate: Ref<string | null> = ref(null)
  const endDate: Ref<string | null> = ref(null)
  const isOpenEnd: Ref<boolean> = ref(false)
  const isRecurring = ref(false)
  const hasExclusionDates = ref(false)
  const hasExclusionRule = ref(false)
  const rruleRef = ref(null)
  const exruleRef = ref(null)
  const exdates: Ref<string[]> = ref([])
  const removeOccurrencesOnHolidays: Ref<boolean> = ref(false)
  const addOccurrencesOnHolidays: Ref<boolean> = ref(false)

  const loading = ref(false)
  const hasErrors: Ref<boolean> = ref(false)
  const errorMessage: Ref<string | null> = ref(null)
  const rules = computed(() => ({
    startDate: { required: required, $autoDirty: true },
    endDate: { required: required, $autoDirty: true },
  }))

  const v$ = useVuelidate(rules, { startDate, endDate })

  const datePickerMode = computed(() => {
    return allDay.value ? 'date' : 'datetime'
  })

  const datePickerMask = computed(() => {
    return 'YYYY-MM-DD HH:mm:ss'
  })

  const datePickerButtonLabel = (date) => {
    return date
      ? allDay.value
        ? format(new Date(date), 'dd.MM.yyyy')
        : format(new Date(date), 'dd.MM.yyyy HH:mm')
      : 'Datum festlegen'
  }

  const closeDialog = () => {
    document.getElementById('createOccurrenceDialog').close()
    resetForm()
    emit('cancelled')
  }

  const resetForm = () => {
    allDay.value = false
    startDate.value = null
    endDate.value = null
    isOpenEnd.value = false
    isRecurring.value = false
    hasExclusionDates.value = false
    hasExclusionRule.value = false
    exdates.value = []

    rruleRef.value?.resetForm()
    exruleRef.value?.resetForm()

    hasErrors.value = false
    v$.value.$reset()
  }

  const createOccurrences = async () => {
    hasErrors.value = false
    const isFormCorrect = await v$.value.$validate()

    if (!isFormCorrect) {
      errorMessage.value = 'Es gab einen Fehler beim Hinzufügen des Termins bzw. der Termine. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneuert.'
      hasErrors.value = true
      return
    }

    if (allDay.value) {
      startDate.value = format(new Date(startDate.value), 'yyyy-MM-dd')
      endDate.value = format(new Date(endDate.value), 'yyyy-MM-dd')
      isOpenEnd.value = false
    }

    let occurrences = [] as EventOccurrence[]

    if (isRecurring.value) {
      occurrences = await createMultiple()
    } else {
      occurrences = createSingle()
    }

    emit('created', occurrences)
    document.getElementById('createOccurrenceDialog').close()
    resetForm()
  }

  const createMultiple = async () => {
    const occurrences = [] as EventOccurrence[]
    let isFormCorrect = await rruleRef.value.validateForm()

    if (!isFormCorrect) {
      errorMessage.value = 'Es gab einen Fehler beim Hinzufügen der Termine. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneuert.'
      hasErrors.value = true
      return
    }

    const rset = new RRuleSet()
    const rsetCopy = new RRuleSet()

    const rrule = rruleRef.value.getRecurrenceRule()
    rset.rrule(rrule)
    rsetCopy.rrule(rrule) // copy for removing occurrences on holidays

    if (hasExclusionRule.value) {
      let isFormCorrect = await exruleRef.value.validateForm()

      if (!isFormCorrect) {
        errorMessage.value = 'Es gab einen Fehler beim Hinzufügen der Termine. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneuert.'
        hasErrors.value = true
        return
      }

      const exrule = exruleRef.value.getRecurrenceRule()
      rset.exrule(exrule)
      rsetCopy.exrule(exrule) // copy for removing occurrences on holidays
    }

    if (hasExclusionDates.value) {
      for (const exdate of exdates.value) {
        const date = new Date(exdate)
        // Set time to start time of the startDate
        date.setUTCHours(new Date(startDate.value).getUTCHours())
        rset.exdate(new Date(date))
        rsetCopy.exdate(new Date(date)) // copy for removing occurrences on holidays
      }
    }

    if (addOccurrencesOnHolidays.value) {
      const allDates = rsetCopy.all() // we need to use the copy here, because the original rset will be modified by the rdate method
      const start = allDates[0]
      const end = allDates[allDates.length - 1]

      const holidays = await getHolidays(start.getFullYear(), end.getFullYear())

      // Check for every holiday if it is in the date range of the rset
      // If it is and the date is not already in the rset, add it
      for (const holiday of holidays) {
        const currentDate = new Date(holiday)

        if (currentDate >= start && currentDate <= end) {
          if (allDates.find((date) => isSameDay(currentDate, date)) === undefined) {
            const date = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), start.getUTCHours(), start.getUTCMinutes()))

            rset.rdate(date)
          }
        }
      }
    }

    if (removeOccurrencesOnHolidays.value) {
      const allDates = rsetCopy.all() // we need to use the copy here, because the original rset will be modified by the exdate method
      const startYear = allDates[0].getFullYear()
      const endYear = allDates[allDates.length - 1].getFullYear()

      const holidays = await getHolidays(startYear, endYear)

      for (const holiday of holidays) {
        for (const date of allDates) {

          if (isSameDay(date, new Date(holiday))) {
            rset.exdate(date)
          }
        }
      }
    }

    const duration = differenceInMilliseconds(new Date(endDate.value), new Date(startDate.value))

    for (const date of rset.all()) {
      let momentDate = moment.utc(date.toISOString())
      let momentEndDate = moment.utc(addMilliseconds(date, duration).toISOString())

      const occurrence: EventOccurrence = {
        id: null,
        start: momentDate.utcOffset(moment().utcOffset()).format('YYYY-MM-DD HH:mm:ss'),
        end: momentEndDate.utcOffset(moment().utcOffset()).format('YYYY-MM-DD HH:mm:ss'),
        open_end: isOpenEnd.value,
        all_day: allDay.value,
        not_saved: true,
      }

      occurrences.push(occurrence)
      store.addOccurrence(occurrence)
    }

    return occurrences
  }

  const createSingle = () => {
    const occurrences = [] as EventOccurrence[]

    const occurrence: EventOccurrence = {
      id: null,
      start: startDate.value,
      end: endDate.value,
      open_end: isOpenEnd.value,
      all_day: allDay.value,
      not_saved: true,
    }

    occurrences.push(occurrence)
    store.addOccurrence(occurrence)

    return occurrences
  }

  const getHolidays = async (start, end) => {
    loading.value = true

    try {
      const response = await axios.get('/api/holidays?start=' + start + '&end=' + end)
      return response.data.holidays
    } catch (error) {
      errorMessage.value = 'Es gab einen Fehler beim Abrufen der Feiertage. Bitte versuchen Sie es erneut.'
      hasErrors.value = true
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  watch(() => props.date, (value) => {
    if (value) {
      startDate.value = format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
    }
  })

  watch(startDate, (value) => {
    if (value) {
      endDate.value = format(addHours(new Date(value), 1), 'yyyy-MM-dd HH:mm:ss')
    }
  })

  watch(isOpenEnd, (value) => {
    if (value) {
      endDate.value = endDate.value ? format(new Date(endDate.value).setHours(23, 59, 59), 'yyyy-MM-dd HH:mm:ss') : null
    } else {
      endDate.value = (endDate.value && startDate.value) ? format(addHours(new Date(startDate.value), 1), 'yyyy-MM-dd HH:mm:ss') : null
    }
  })
</script>

<template>
  <dialog id="createOccurrenceDialog" class="modal">
    <div class="modal-box overflow-auto xs:w-[600px] relative">
      <h3 class="font-bold text-lg">Neuen Termin erstellen</h3>
      <div class="w-full py-4 min-h-[400px]">
        <div role="alert" class="alert alert-error text-white mb-4" v-if="hasErrors">
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>{{ errorMessage }}</span>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-1/2">Ganztägig</div>
          <input type="checkbox" class="toggle toggle-sm" v-model="allDay" />
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-1/2">Beginn</div>
          <div class="w-1/2">
            <Popover class="relative overflow">
              <PopoverButton><button class="btn btn-sm">{{ datePickerButtonLabel(startDate) }}</button></PopoverButton>

              <PopoverPanel class="absolute z-[1000] right-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="startDate" :mode="datePickerMode" :masks="{ modelValue: datePickerMask }" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
          <small class="text-xs text-error w-full" v-if="v$.startDate.$error">Beginn ist ein Pflichtfeld</small>
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-1/2">Ende</div>
          <div class="w-1/2">
            <Popover class="relative overflow">
              <PopoverButton><button class="btn btn-sm">{{ isOpenEnd && endDate ? format(new Date(endDate), 'dd.MM.yyyy') : datePickerButtonLabel(endDate) }}</button></PopoverButton>

              <PopoverPanel class="absolute z-10 right-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="endDate" :mode="isOpenEnd ? 'date' : datePickerMode" :masks="{ modelValue: datePickerMask }" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
          <small class="text-xs text-error w-full" v-if="v$.endDate.$error">Ende ist ein Pflichtfeld</small>
        </div>
        <div class="flex items-center mb-4" v-if="!allDay">
          <input type="checkbox" class="toggle toggle-sm" v-model="isOpenEnd" :disabled="allDay" />
          <div class="ml-2">Open End</div>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-1/2">Der Termin wiederholt sich</div>
          <input type="checkbox" class="toggle toggle-sm" v-model="isRecurring" />
        </div>
        <div v-if="isRecurring">
          <RecurrenceForm ref="rruleRef" :startDate="startDate"/>
          <div class="flex items-center mb-4">
            <input type="checkbox" class="toggle toggle-sm" v-model="addOccurrencesOnHolidays" :disabled="removeOccurrencesOnHolidays" />
            <div class="ml-2">Termine an allen Feiertagen innerhalb des Zeitraums erstellen</div>
          </div>
          <div class="flex items-center mb-4">
            <input type="checkbox" class="toggle toggle-sm" v-model="removeOccurrencesOnHolidays" :disabled="addOccurrencesOnHolidays" />
            <div class="ml-2">Keine Termine an Feiertagen erstellen</div>
          </div>
          <div class="flex items-center mb-4">
            <input type="checkbox" class="toggle toggle-sm" v-model="hasExclusionDates" />
            <div class="ml-2">Ausnahme-Daten hinzufügen</div>
          </div>
          <div class="card bg-base-200 mb-4" v-if="hasExclusionDates">
            <div class="card-body">
              <div v-for="(date, index) in exdates">
                <div class="flex items-center mb-2">
                  <Popover class="relative overflow mr-2 w-full">
                    <PopoverButton class="w-full"><button class="btn btn-sm btn-neutral w-full text-white">{{ date ? format(date, 'dd.MM.yyyy') : 'Datum auswählen' }}</button></PopoverButton>

                    <PopoverPanel class="absolute z-[1000] right-0" v-slot="{ close }">
                      <div class="card bg-base-100 card-bordered">
                        <div class="card-body p-2">
                          <DatePicker v-model.string="exdates[index]" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header is24hr />
                        </div>
                      </div>
                    </PopoverPanel>
                  </Popover>
                  <button class="btn btn-square btn-outline btn-error btn-sm fill-error" @click.prevent="() => exdates.splice(index, 1)" :disabled="exdates.length < 2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>delete-outline</title><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
                  </button>
                </div>
              </div>
              <button class="btn btn-block btn-success btn-sm text-neutral-content text-white" @click.prevent="() => exdates.push(format(new Date(), 'yyyy-MM-dd'))">+ Weiteren Tag hinzufügen</button>
            </div>
          </div>
          <div class="flex items-center mb-4">
            <input type="checkbox" class="toggle toggle-sm" v-model="hasExclusionRule" />
            <div class="ml-2">Ausnahmeregel hinzufügen</div>
          </div>
          <div class="card bg-base-200" v-if="hasExclusionRule">
            <div class="card-body">
              <RecurrenceForm ref="exruleRef" :startDate="startDate" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-action grow">
        <form method="dialog">
          <div class="h-full flex items-end">
            <button class="btn btn-success text-white mr-2" @click.prevent="createOccurrences()">Hinzufügen</button>
            <button class="btn" @click.prevent="closeDialog()">Abbrechen</button>
          </div>
          <!-- if there is a button in form, it will close the modal -->
        </form>
      </div>
      <div v-if="loading" class="absolute inset-0 bg-base-100 bg-opacity-50 z-10 flex items-center justify-center">
        <span class="loading loading-spinner loading-lg"></span>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button @click.prevent="closeDialog()">close</button>
    </form>
  </dialog>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, Ref } from 'vue'

const props = defineProps(['events'])

const events: Ref<any[]> = ref([])
const page: Ref<number> = ref(0)

onMounted(() => {
  for (let i = 0; i < props.events.length; i += 10) {
    events.value.push(props.events.slice(i, i + 10))
  }
})


</script>

<template>
  <div v-if="events.length === 0" role="alert" class="alert mt-2">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <span>Keine Events gefunden.</span>
  </div>
  <ul v-else class="mt-2">
    <li v-for="(event, index) in events[page]" :key="index">
      <a :href="`/event/${event.id}`" class="p-2 block hover:bg-primary-content rounded-md flex justify-between items-center">
        {{ event.title.length > 50 ? event.title.substring(0, 50) + '...' : event.title }}
        <div>
          <a :href="`/event/${event.id}?tab=2`" class="btn btn-square mr-2"><svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>text-box-edit-outline</title><path d="M10 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V10.33C20.7 10.21 20.37 10.14 20.04 10.14C19.67 10.14 19.32 10.22 19 10.37V5H5V19H10.11L10 19.11V21M7 9H17V7H7V9M7 17H12.11L14 15.12V15H7V17M7 13H16.12L17 12.12V11H7V13M21.7 13.58L20.42 12.3C20.21 12.09 19.86 12.09 19.65 12.3L18.65 13.3L20.7 15.35L21.7 14.35C21.91 14.14 21.91 13.79 21.7 13.58M12 22H14.06L20.11 15.93L18.06 13.88L12 19.94V22Z" /></svg></a>
          <a :href="`/event/${event.id}?tab=1`" class="btn btn-square"><svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>calendar-edit</title><path d="M19,3H18V1H16V3H8V1H6V3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H10V19H5V8H19V9H21V5A2,2 0 0,0 19,3M21.7,13.35L20.7,14.35L18.65,12.35L19.65,11.35C19.85,11.14 20.19,11.13 20.42,11.35L21.7,12.63C21.89,12.83 21.89,13.15 21.7,13.35M12,18.94L18.07,12.88L20.12,14.88L14.06,21H12V18.94Z" /></svg></a>
        </div>
      </a>
      <div v-if="index + 1 != events[page]?.length" class="divider m-0 p-0"></div>
    </li>
  </ul>
  <div v-if="events.length > 1" class="flex justify-end mt-2">
      <div class="join">
        <button class="join-item btn" :disabled="page === 0" @click.prevent="() => page = page - 1">«</button>
        <button class="join-item btn" :disabled="page === events.length - 1" @click.prevent="() => page = page + 1">»</button>
      </div>
  </div>
</template>

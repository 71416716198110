<script setup lang="ts">
  import { isSameDay } from 'date-fns';
  import { defineEmits, computed, ref, Ref } from 'vue';

  import { useEventStore } from '@/store/event'

  const emit = defineEmits(['editOccurrence'])

  const store = useEventStore()
  const months = ['Alle Monate anzeigen', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  const selectedYear: Ref<string> = ref('Alle Jahre anzeigen');
  const selectedMonth: Ref<string> = ref('Alle Monate anzeigen');

  const occurrences = computed(() => {
    const filteredOccurrences = store.occurrences.filter((item) => {
      const start = new Date(item.start);
      return selectedYear.value === 'Alle Jahre anzeigen' || start.getFullYear().toString() === selectedYear.value;
    }).filter((item) => {
      const start = new Date(item.start);
      return selectedMonth.value === 'Alle Monate anzeigen' || months[start.getMonth() + 1] === selectedMonth.value;
    });

    return filteredOccurrences.map((item) => {
      const start = new Date(item.start);
      const end = new Date(item.end);

      return {
        id: item.id,
        date: isSameDay(start, end) || item.open_end
          ? start.toLocaleDateString('de-DE')
          : `${start.toLocaleDateString('de-DE')} - ${end.toLocaleDateString('de-DE')}`,
        weekday: start.toLocaleDateString('de-DE', { weekday: 'long' }),
        time: item.all_day
          ? 'Ganztätig'
          : item.open_end
            ? `Ab ${start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`
            : `${start.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })} - ${end.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`
      }
    })
  });

  const years = computed(() => {
    const years = store.occurrences.map((item) => {
      const start = new Date(item.start);
      return start.getFullYear();
    });

    return [ 'Alle Jahre anzeigen', ...new Set(years)];
  });
</script>

<template>
  <div class="flex justify-end items-center">
    <select class="select select-bordered w-full max-w-xs mr-4" v-model="selectedMonth">
      <option v-for="(month, index) in months" :key="index">{{ month }}</option>
    </select>
    <select class="select select-bordered w-full max-w-xs" v-model="selectedYear">
      <option v-for="(year, index) in years" :key="index">{{ year }}</option>
    </select>
  </div>
  <div class="overflow-x-auto">
    <table class="table">
      <!-- head -->
      <thead>
        <tr>
          <th>Datum</th>
          <th>Wochentag</th>
          <th>Uhrzeit</th>
        </tr>
      </thead>
      <tbody v-if="occurrences?.length > 0">
        <tr v-for="occurrence in occurrences" :key="occurrence?.id" @click="emit('editOccurrence', occurrence?.id)" class="hover:bg-primary-content cursor-pointer">
          <td>{{ occurrence?.date }}</td>
          <td>{{ occurrence?.weekday }}</td>
          <td>{{ occurrence?.time }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3" class="text-center italic">Keine Termine vorhanden</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
  import { defineProps, defineEmits, ref, Ref, computed, watch } from 'vue'
  import { useVuelidate } from '@vuelidate/core'
  import { required, requiredIf } from '@vuelidate/validators'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { DatePicker } from 'v-calendar'
  import 'v-calendar/style.css'
  import { format, addHours } from "date-fns";

  import { useEventStore,  } from '@/store/event'

  interface EventOccurrence {
    id: number | null
    start: string
    end: string
    allDay: boolean
  }

  const props = defineProps(['occurrence'])
  const emit = defineEmits(['updated', 'cancelled'])

  const store = useEventStore()

  const allDay: Ref<boolean> = ref(false)
  const startDate: Ref<string | null> = ref(null)
  const endDate: Ref<string | null> = ref(null)
  const isOpenEnd: Ref<boolean> = ref(false)
  const updateAll: Ref<boolean> = ref(false)
  const updateInRange: Ref<boolean> = ref(false)
  const rangeStart: Ref<string | null> = ref(null)
  const rangeEnd: Ref<string | null> = ref(null)
  const updateWeekdays: Ref<boolean> = ref(false)
  const weekdays: Ref<number[]> = ref([])

  const hasErrors: Ref<boolean> = ref(false)
  const rules = computed(() => ({
    startDate: { required: required, $autoDirty: true },
    endDate: { required: required, $autoDirty: true },
    rangeStart: { requiredIf: requiredIf(updateInRange), $autoDirty: true },
    rangeEnd: { requiredIf: requiredIf(updateInRange), $autoDirty: true },
    weekdays: { requiredIf: requiredIf(updateWeekdays), $autoDirty: true }
  }))
  const v$ = useVuelidate(rules, { startDate, endDate, rangeStart, rangeEnd, weekdays })

  const datePickerMode = computed(() => {
    return allDay.value ? 'date' : 'datetime'
  })

  const datePickerMask = computed(() => {
    return 'YYYY-MM-DD HH:mm:ss'
  })

  const datePickerButtonLabel = (date) => {
    return date
      ? allDay.value
        ? format(new Date(date), 'dd.MM.yyyy')
        : format(new Date(date), 'dd.MM.yyyy HH:mm')
      : 'Datum festlegen'
  }

  const resetForm = () => {
    allDay.value = false
    startDate.value = null
    endDate.value = null
    isOpenEnd.value = false
    updateAll.value = false
    updateInRange.value = false
    rangeStart.value = null
    rangeEnd.value = null
    updateWeekdays.value = false
    weekdays.value = []

    hasErrors.value = false
    v$.value.$reset()
  }

  const updateOccurrence = async () => {
    hasErrors.value = false
    v$.value.$touch()

    if (v$.value.$error) {
      hasErrors.value = true
      return
    }

    if (allDay.value) {
      startDate.value = format(new Date(startDate.value), 'yyyy-MM-dd')
      endDate.value = format(new Date(endDate.value), 'yyyy-MM-dd')
    }

    const newOccurrence: EventOccurrence = {
      id: parseInt(props.occurrence.id),
      start: startDate.value,
      end: endDate.value,
      open_end: isOpenEnd.value,
      all_day: allDay.value,
      not_saved: true
    }

    if (updateAll.value) {
      if (!updateWeekdays.value) {
        weekdays.value = []
      }

      if (updateInRange.value) {
        store.updateSimilarOccurrences(newOccurrence, props.occurrence, weekdays.value, rangeStart.value, rangeEnd.value)
      } else {
        store.updateSimilarOccurrences(newOccurrence, props.occurrence, weekdays.value)
      }
    } else {
      store.updateOccurrence(newOccurrence)
    }

    document.getElementById('editOccurrenceDialog').close()
    resetForm()
    emit('updated')
  }

  const openDeleteDialog = () => {
    document.getElementById('confirmDelete').showModal()
  }

  const removeOccurrence = async () => {
    hasErrors.value = false
    v$.value.$touch()

    if (v$.value.$error) {
      hasErrors.value = true
      return
    }

    if (updateAll.value) {
      if (!updateWeekdays.value) {
        weekdays.value = []
      }

      if (updateInRange.value) {
        store.removeSimilarOccurrences(props.occurrence, weekdays.value, rangeStart.value, rangeEnd.value)
      } else {
        store.removeSimilarOccurrences(props.occurrence, weekdays.value)
      }
    } else {
      store.removeOccurrence(props.occurrence)
    }

    document.getElementById('confirmDelete').close()

    document.getElementById('editOccurrenceDialog').close()
    resetForm()
    emit('updated')
  }

  const closeDialog = () => {
    document.getElementById('editOccurrenceDialog').close()
    resetForm()
    emit('cancelled')
  }

  watch(() => props.occurrence, (occurrence) => {
    if (occurrence === null) {
      return
    }

    allDay.value = occurrence.all_day
    startDate.value = occurrence.start
    endDate.value = occurrence.end
    isOpenEnd.value = occurrence.open_end
  })

  watch(isOpenEnd, (value) => {
    if (value) {
      endDate.value = format(new Date(endDate.value).setHours(23, 59, 59), 'yyyy-MM-dd HH:mm:ss')
    } else {
      endDate.value = format(addHours(new Date(startDate.value), 1), 'yyyy-MM-dd HH:mm:ss')
    }
  })
</script>

<template>
  <dialog id="editOccurrenceDialog" class="modal">
    <div class="modal-box overflow-auto xs:w-[600px]">
      <h3 class="font-bold text-lg">Termin bearbeiten</h3>
      <div class="w-full py-4 min-h-[400px]">
        <div role="alert" class="alert alert-error text-white mb-4" v-if="hasErrors">
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>Es gab einen Fehler beim Bearbeiten des Termins. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneuert.</span>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-1/2">Ganztägig</div>
          <input type="checkbox" class="toggle toggle-sm" v-model="allDay" />
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-1/2">Beginn</div>
          <div class="w-1/2">
            <Popover class="relative overflow">
              <PopoverButton><button class="btn btn-sm">{{ datePickerButtonLabel(startDate) }}</button></PopoverButton>

              <PopoverPanel class="absolute z-[1000] right-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="startDate" :mode="datePickerMode" :masks="{ modelValue: datePickerMask }" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
          <small class="text-xs text-error w-full" v-if="v$.startDate.$error">Beginn ist ein Pflichtfeld</small>
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-1/2">Ende</div>
          <div class="w-1/2">
            <Popover class="relative overflow">
              <PopoverButton><button class="btn btn-sm">{{ isOpenEnd ? format(new Date(endDate), 'dd.MM.yyyy') : datePickerButtonLabel(endDate) }}</button></PopoverButton>

              <PopoverPanel class="absolute z-10 right-0" v-slot="{ close }">
                <div class="card bg-base-100 card-bordered">
                  <div class="card-body p-2">
                    <DatePicker v-model.string="endDate" :mode="isOpenEnd ? 'date' : datePickerMode" :masks="{ modelValue: datePickerMask }" transparent borderless hide-time-header is24hr />
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
          <small class="text-xs text-error w-full" v-if="v$.endDate.$error">Ende ist ein Pflichtfeld</small>
        </div>
        <div class="flex items-center mb-4" v-if="!allDay">
          <input type="checkbox" class="toggle toggle-sm" v-model="isOpenEnd" />
          <div class="ml-2">Open End</div>
        </div>
        <div class="flex items-start mb-4" v-if="props.occurrence">
          <input type="checkbox" class="checkbox checkbox-sm mt-1" v-model="updateAll"/>
          <span class="ml-2">Alle Termine aktualisieren, die zum gleichen Zeitpunkt starten und enden {{ props.occurrence.all_day ? '' : '(' + format(new Date(props.occurrence.start), 'HH:mm') + ' - ' + format(new Date(props.occurrence.end), 'HH:mm') + ')' }}</span>
        </div>
        <div class="flex items-start mb-4" v-if="props.occurrence">
          <input type="checkbox" class="checkbox checkbox-sm mt-1" :disabled="!updateAll" v-model="updateInRange"/>
          <span class="ml-2">Auf einen bestimmten Zeitraum beschränken</span>
        </div>
        <div class="flex items-center mb-4 ml-7" v-if="updateInRange">
          <span class="mr-2">Von</span>
          <Popover class="relative overflow mx-2">
            <PopoverButton><button class="btn btn-sm">{{ rangeStart ? format(new Date(rangeStart), 'dd.MM.yyyy') : 'Datum festlegen' }}</button></PopoverButton>

            <PopoverPanel class="absolute z-10 left-0" v-slot="{ close }">
              <div class="card bg-base-100 card-bordered">
                <div class="card-body p-2">
                  <DatePicker v-model.string="rangeStart" mode="date" @dayclick="close" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header  />
                </div>
              </div>
            </PopoverPanel>
          </Popover>
          <span class="mx-2">bis</span>
          <Popover class="relative overflow ml-2">
            <PopoverButton><button class="btn btn-sm">{{ rangeEnd ? format(new Date(rangeEnd), 'dd.MM.yyyy') : 'Datum festlegen' }}</button></PopoverButton>

            <PopoverPanel class="absolute z-10 right-0" v-slot="{ close }">
              <div class="card bg-base-100 card-bordered">
                <div class="card-body p-2">
                  <DatePicker v-model.string="rangeEnd" mode="date" @dayclick="close" :masks="{ modelValue: 'YYYY-MM-DD' }" transparent borderless hide-time-header  />
                </div>
              </div>
            </PopoverPanel>
          </Popover>
        </div>
        <div class="flex items-start mb-4" v-if="props.occurrence">
          <input type="checkbox" class="checkbox checkbox-sm mt-1" :disabled="!updateAll" v-model="updateWeekdays"/>
          <span class="ml-2">Auf bestimmte Wochentage beschränken</span>
        </div>
        <div class="flex items-center mb-4 ml-7" v-if="updateWeekdays">
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="1"/><span class="mx-2">Mo</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="2"/><span class="mx-2">Di</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="3"/><span class="mx-2">Mi</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="4"/><span class="mx-2">Do</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="5"/><span class="mx-2">Fr</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="6"/><span class="mx-2">Sa</span>
          <input type="checkbox" class="checkbox checkbox-sm" v-model="weekdays" :value="0"/><span class="mx-2">So</span>
        </div>
        <div class="text-xs text-error w-full mt-2" v-if="v$.weekdays.$error">Bitte wählen Sie mindestens ein Wochentag aus, um die Auswahl zu beschränken.</div>
      </div>
      <div class="modal-action">
        <form method="dialog" class="w-full">
          <div class="flex justify-between">
            <button class="btn btn-error text-white mr-2" @click.prevent="openDeleteDialog">{{ updateAll ? 'Alle Löschen' : 'Löschen' }}</button>
            <div class="flex justify-end">
              <button class="btn btn-success text-white mr-2" @click.prevent="updateOccurrence">Aktualisieren</button>
              <button class="btn" @click.prevent="closeDialog">Abbrechen</button>
            </div>
          </div>
          <!-- if there is a button in form, it will close the modal -->
        </form>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button @click.prevent="closeDialog()">close</button>
    </form>
  </dialog>
  <dialog id="confirmDelete" class="modal">
    <div class="modal-box">
      <h3 class="font-bold text-lg">{{ updateAll ? 'Achtung: Sollen mehrere Termine gelöscht werden?' : 'Termin löschen'}}</h3>
      <p class="py-4">{{ updateAll ? 'Möchten Sie alle Termine löschen, die der Auswahl entsprechen?' : 'Möchten Sie den Termin wirklich löschen?' }}</p>
      <div class="modal-action">
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn btn-error text-white mr-2" @click.prevent="removeOccurrence">{{ updateAll ? 'Alle Löschen' : 'Löschen' }}</button>
          <button class="btn">Abbrechen</button>
        </form>
      </div>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

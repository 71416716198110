<script setup lang="ts">
import { ref, Ref, defineProps, computed } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import deLocale from '@fullcalendar/core/locales/de'
import axios from 'axios'

import { EventOccurrence } from '@/types'

// const props = defineProps(['allOccurrences'])

const fullCalendar: Ref<any|null> = ref(null)
const occurrences: Ref<EventOccurrence[]> = ref([])
const loading: Ref<boolean> = ref(false)
const yearInView: Ref<number|null> = ref(null)
const holidays = ref([])

const getHolidays = async () => {
  if (!yearInView.value) return

  try {
    const response = await axios.get(`/api/holidays?start=${yearInView.value - 1}&end=${yearInView.value + 1}`)
    const data = await response.data

    holidays.value = data.holidays
    // renderCalendar()
  } catch (error) {
    console.error(error)
  }
}

const calendarEvents = computed(() => {
  const events = occurrences.value.map((occurrence) => {
    return {
      id: occurrence.id,
      title: occurrence?.event?.title,
      start: occurrence.start,
      end: occurrence.end,
      allDay: occurrence.all_day,
      extendedProps: {
        eventId: occurrence.event_id,
      }
      // backgroundColor: occurrence.not_saved ? '#f0ad4e' : null,
    }
  })

  for (const holiday of holidays.value) {
    if (events.find((event) => event.start === holiday && event?.display === 'background')) continue

    events.push({
      start: holiday,
      allDay: true,
      backgroundColor: '#d66f6f',
      borderColor: '#d66f6f',
      display: 'background',
    })
  }

  return events
})

const calendarOptions = computed(() => {
  return {
    plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'timeGridDay',
    locale: deLocale,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    datesSet: async (info) => {
      loading.value = true

      const start = info.start.toISOString()
      const end = info.end.toISOString()

      try {
        const response = await axios.get(`/api/occurrences/range?start=${start}&end=${end}`)
        occurrences.value = response.data
      } catch (error) {
        console.error(error)
      }

      if (yearInView.value !== info.start.getFullYear()) {
        yearInView.value = info.start.getFullYear()
        await getHolidays()
      }

      loading.value = false
    },
    loading: loading.value,
    weekNumbers: true,
    nowIndicator: true,
    selectable: true,
    dateClick: (info) => {
      if (info.view.type === 'timeGridWeek') {
        fullCalendar.value?.getApi().changeView('timeGridDay', info.dateStr)
      }
    },
    events: calendarEvents.value,
    editable: true,
    eventClick: function (info) {
      location.href = `/event/${info.event.extendedProps.eventId}?tab=1&date=${info.event.start.toISOString()}`
    },
  }
})
</script>

<template>
  <div class="card bg-base-100">
  <div class="card-body">
    <div class="flex justify-between mb-4">
      <div class="flex items-center">
        <h2 class="card-title text-2xl">Termine</h2>
        <!-- <div v-if="store.hasUnsavedOccurrences" class="ml-2">
          <span class="badge badge-warning badge-md h-7 gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><title>alert-circle-outline</title><path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" /></svg>
            Ungespeicherte Änderungen
          </span>
        </div> -->
      </div>
      <!-- <button class="btn btn-primary" onclick="createOccurrenceDialog.showModal()">+ Neuer Termin</button> -->
    </div>
    <div class="relative">
      <FullCalendar :options="calendarOptions" ref="fullCalendar" class="relative z-0"/>
      <div v-if="loading" class="absolute inset-0 bg-base-100 bg-opacity-50 z-10 flex items-center justify-center">
        <span class="loading loading-spinner loading-lg"></span>
      </div>
    </div>
  </div>
</div>
</template>
